<template>
  <v-card max-width="500">
    <v-card-title>ログイン<v-spacer /> </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <v-form @submit.prevent="onLogin">
              <v-card flat>
                <validation-provider v-slot="{ errors }" name="email" rules="required|max:100">
                  <v-text-field
                    label="email"
                    :rules="[(v) => !!v || 'email は必須です']"
                    :maxlength="100"
                    :error-messages="errors"
                    v-model="creator.email"
                    @keyup.enter="onLogin"
                  />
                </validation-provider>

                <validation-provider v-slot="{ errors }" name="password" rules="required|max:100">
                  <v-text-field
                    type="password"
                    label="password"
                    :rules="[(v) => !!v || 'password は必須です']"
                    :maxlength="100"
                    :error-messages="errors"
                    v-model="creator.password"
                    @keyup.enter="onLogin"
                  />
                </validation-provider>

                <v-checkbox label="保存" v-model="creator.save" />
                <v-card-actions>
                  <!-- <v-btn text @click="onChange(State.Forget)">パスワードを忘れた方</v-btn> -->
                  <v-spacer />
                  <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onLogin">
                    <v-icon v-text="'mdi-login-variant'" left /> ログイン</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <!-- いったん非活性-->
      <!-- <v-btn text @click="onChange(State.Create)">新規登録</v-btn> -->
    </v-card-actions>
  </v-card>
</template>
<script>
import { State } from './Signin';

export default {
  components: {},
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    State() {
      return State;
    },
  },

  props: {
    observer: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    initializing: true,

    creator: {
      loading: false,

      email: null,
      password: null,
      save: false,
    },
  }),
  methods: {
    onLoaded() {
      this.loadCookie();

      this.initializing = false;
    },

    loadCookie() {
      let email = this.$cookies.get('email');
      let password = this.$cookies.get('password');
      let save = this.$cookies.get('save');
      //
      email = email == 'null' ? null : email;
      password = password == 'null' ? null : password;
      save = save != 'null';

      this.creator.email = email;
      this.creator.password = password;
      this.creator.save = save;
    },

    onChange(val) {
      this.$router.push({ name: 'signin', params: { state: val } });
    },

    async onLogin() {
      console.log('onLogin');
      //
      if (!(await this.form.validate())) return;

      //
      this.creator.loading = true;

      let request = {
        email: this.creator.email,
        password: this.creator.password,
        device: '',
        rorls: [],
      };
      this.post('auth/user/login', request)
        .then((success) => {
          //
          let data = success.data;
          let token = data.id_token;
          let user = data.user;

          let u = {
            code: user.code,
            name: user.name,
            email: user.email,
            avatar: {
              src: user.avatar.src,
            },
          };

          // ユーザ情報設定
          this.$store.commit('setUser', u);
          // ログイン情報設定
          this.$store.commit('setUserToken', token);

          // cookie保存
          if (this.creator.save) {
            this.$cookies.config(60 * 60 * 24 * 30, '');
            this.$cookies.set('email', this.creator.email);
            this.$cookies.set('password', this.creator.password);
            this.$cookies.set('save', true);
          } else {
            this.$cookies.set('email', null);
            this.$cookies.set('password', null);
            this.$cookies.set('save', false);
          }

          // スプラッシュ完了まで待たせる
          this.$store
            .dispatch('splashApp')
            .then(() => {
              //
              this.$store.commit('setSnackbar', {
                show: true,
                color: 'success',
                message: 'ログインしました',
              });

              // this.$router.push({ name: "Forms", params: { form: "dashboard" } });
              this.$emit('commit');
            })
            .catch((ex) => {
              console.log('login ex', ex);
              throw new Error('アプリケーション起動に失敗しました');
            });
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'error',
            message: 'ログインできませんでした',
          });

          //ユーザ情報削除
          this.$store.commit('clearUser');
        })
        .finally(() => {
          this.creator.loading = false;
        });
    },
  },
  watch: {},
  mounted() {
    setTimeout(() => {
      // 反映されるのを待ってから実行
      // 初期処理
      this.onLoaded();
    }, 0);
  },
};
</script>
