var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_vm._v("ver,"+_vm._s(_vm.version))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer){return [(_vm.state == _vm.State.Login && !_vm.isLogined)?_c('Login',{attrs:{"observer":observer},on:{"commit":_vm.onLogin}}):_vm._e()]}}])}),(_vm.state == _vm.State.CreateConfirmed)?_c('v-card',{attrs:{"max-width":"500"}},[_c('v-card-title',[_vm._v("承認")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.confirmed.message)+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onChange(_vm.State.Login)}}},[_vm._v("ログイン")])],1)],1):_vm._e(),(_vm.state == _vm.State.Forget)?_c('v-card',{attrs:{"max-width":"500"}},[_c('v-card-title',[_vm._v("パスワード再登録")]),_c('v-card-text',[_c('v-form',{ref:"Forget"},[_c('v-card',[_c('validation-provider',{attrs:{"name":"email","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"email","rules":[function (v) { return !!v || 'email は必須です'; }],"counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.forget.email),callback:function ($$v) {_vm.$set(_vm.forget, "email", $$v)},expression:"forget.email"}})]}}],null,false,1115196024)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onForget}},[_vm._v("再発行")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onChange(_vm.State.Login)}}},[_vm._v("ログイン")])],1)],1):_vm._e(),(_vm.state == _vm.State.Create)?_c('v-card',{attrs:{"max-width":"500"}},[_c('v-card-title',[_vm._v("新規登録")]),_c('v-card-text',[_c('v-form',{ref:"Create"},[_c('v-card',{attrs:{"flat":""}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"email","rules":[function (v) { return !!v || 'email は必須です'; }],"counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.creator.email),callback:function ($$v) {_vm.$set(_vm.creator, "email", $$v)},expression:"creator.email"}})]}}],null,false,3321208681)}),_c('validation-provider',{attrs:{"name":"password","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"password","rules":[function (v) { return !!v || 'password は必須です'; }],"counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.creator.password),callback:function ($$v) {_vm.$set(_vm.creator, "password", $$v)},expression:"creator.password"}})]}}],null,false,834528683)}),_c('validation-provider',{attrs:{"name":"再入力","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"再入力","rules":[
                !!_vm.creator.confirmedPassword || '再入力 は必須です',
                _vm.creator.password === _vm.creator.confirmedPassword || 'パスワードが一致しません' ],"counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.creator.confirmedPassword),callback:function ($$v) {_vm.$set(_vm.creator, "confirmedPassword", $$v)},expression:"creator.confirmedPassword"}})]}}],null,false,2607746283)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onPost}},[_vm._v("登録")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onChange(_vm.State.Login)}}},[_vm._v("ログイン")])],1)],1):_vm._e(),(_vm.state == _vm.State.ResetPassword)?_c('v-card',{attrs:{"max-width":"500"}},[_c('v-card-title',[_vm._v("パスワード再登録")]),_c('v-card-text',[_c('v-form',{ref:"ResetPassword"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-card-title',[_vm._v("code")]),_c('v-card-text',[_vm._v(_vm._s(_vm.code))]),_c('v-card-title',[_vm._v("email")]),_c('v-card-text',[_vm._v(_vm._s(_vm.email))])],1),_c('validation-provider',{attrs:{"name":"password","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"password","hint":"6文字以上、3文字以上異なる文字を含ませてください","rules":[function (v) { return !!v || 'password は必須です'; }],"counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.resetPassword.password),callback:function ($$v) {_vm.$set(_vm.resetPassword, "password", $$v)},expression:"resetPassword.password"}})]}}],null,false,4020788625)}),_c('validation-provider',{attrs:{"name":"再入力","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"再入力","rules":[
                !!_vm.resetPassword.confirmedPassword || '再入力 は必須です',
                _vm.resetPassword.password === _vm.resetPassword.confirmedPassword || 'パスワードが一致しません' ],"counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.resetPassword.confirmedPassword),callback:function ($$v) {_vm.$set(_vm.resetPassword, "confirmedPassword", $$v)},expression:"resetPassword.confirmedPassword"}})]}}],null,false,3827507051)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onResetPassword}},[_vm._v("発行")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onChange(_vm.State.Login)}}},[_vm._v("ログイン")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }