<template>
  <v-container>
    <div>ver,{{ version }}</div>

    <ValidationObserver v-slot="observer">
      <Login v-if="state == State.Login && !isLogined" :observer="observer" @commit="onLogin" />
    </ValidationObserver>

    <v-card max-width="500" v-if="state == State.CreateConfirmed">
      <v-card-title>承認</v-card-title>
      <v-card-text>
        {{ confirmed.message }}
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onChange(State.Login)">ログイン</v-btn>
      </v-card-actions>
    </v-card>

    <v-card max-width="500" v-if="state == State.Forget">
      <v-card-title>パスワード再登録</v-card-title>
      <v-card-text>
        <v-form ref="Forget">
          <v-card>
            <validation-provider v-slot="{ errors }" name="email" rules="required|max:100">
              <v-text-field
                v-model="forget.email"
                label="email"
                :rules="[(v) => !!v || 'email は必須です']"
                counter
                :maxlength="100"
                :error-messages="errors"
              />
            </validation-provider>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="onForget">再発行</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onChange(State.Login)">ログイン</v-btn>
      </v-card-actions>
    </v-card>

    <v-card max-width="500" v-if="state == State.Create">
      <v-card-title>新規登録</v-card-title>
      <v-card-text>
        <v-form ref="Create">
          <v-card flat>
            <validation-provider v-slot="{ errors }" name="email" rules="required|max:100">
              <v-text-field
                v-model="creator.email"
                label="email"
                :rules="[(v) => !!v || 'email は必須です']"
                counter
                :maxlength="100"
                :error-messages="errors"
              />
            </validation-provider>

            <validation-provider v-slot="{ errors }" name="password" rules="required|max:100">
              <v-text-field
                type="password"
                v-model="creator.password"
                label="password"
                :rules="[(v) => !!v || 'password は必須です']"
                counter
                :maxlength="100"
                :error-messages="errors"
              />
            </validation-provider>

            <validation-provider v-slot="{ errors }" name="再入力" rules="required|max:100">
              <v-text-field
                type="password"
                v-model="creator.confirmedPassword"
                label="再入力"
                :rules="[
                  !!creator.confirmedPassword || '再入力 は必須です',
                  creator.password === creator.confirmedPassword || 'パスワードが一致しません',
                ]"
                counter
                :maxlength="100"
                :error-messages="errors"
              />
            </validation-provider>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="onPost">登録</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onChange(State.Login)">ログイン</v-btn>
      </v-card-actions>
    </v-card>

    <v-card max-width="500" v-if="state == State.ResetPassword">
      <v-card-title>パスワード再登録</v-card-title>
      <v-card-text>
        <v-form ref="ResetPassword">
          <v-card flat>
            <v-card-text>
              <v-card-title>code</v-card-title>
              <v-card-text>{{ code }}</v-card-text>
              <v-card-title>email</v-card-title>
              <v-card-text>{{ email }}</v-card-text>
            </v-card-text>
            <validation-provider v-slot="{ errors }" name="password" rules="required|max:100">
              <v-text-field
                type="password"
                v-model="resetPassword.password"
                label="password"
                hint="6文字以上、3文字以上異なる文字を含ませてください"
                :rules="[(v) => !!v || 'password は必須です']"
                counter
                :maxlength="100"
                :error-messages="errors"
              />
            </validation-provider>

            <validation-provider v-slot="{ errors }" name="再入力" rules="required|max:100">
              <v-text-field
                type="password"
                label="再入力"
                v-model="resetPassword.confirmedPassword"
                :rules="[
                  !!resetPassword.confirmedPassword || '再入力 は必須です',
                  resetPassword.password === resetPassword.confirmedPassword || 'パスワードが一致しません',
                ]"
                counter
                :maxlength="100"
                :error-messages="errors"
              />
            </validation-provider>

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="onResetPassword">発行</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onChange(State.Login)">ログイン</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import Login from './Login';
export const State = {
  Login: 1,
  Create: 1 << 1,
  CreateConfirmed: 1 << 2,
  Forget: 1 << 3,
  ResetPassword: 1 << 4,
};

export default {
  components: { Login },
  computed: {
    state() {
      let state = this.$route.params.state;
      if (!state) state = State.Login;
      return state;
    },

    State() {
      return State;
    },
    token() {
      return this.$route.query.token;
    },
    code() {
      return this.$route.query.code;
    },
    email() {
      return this.$route.query.email;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  props: {},
  data: () => ({
    login: {
      loading: false,
      email: null,
      password: null,
      save: false,
    },

    creator: {
      email: null,
      password: '',
      confirmedPassword: '',
    },

    confirmed: {
      result: false,
      message: '',
    },

    forget: {
      email: null,
    },

    resetPassword: {
      password: null,
      confirmedPassword: null,
    },
  }),
  methods: {
    movePage() {
      this.$router.push({ path: '/users/dashboard' });
    },

    onLoaded() {
      // ログインしていればトップへ移動
      if (this.isLogined) {
        this.movePage();
        return;
      }
      //
      if (this.state == State.CreateConfirmed) {
        let request = {
          code: this.code,
          email: this.email,
          token: this.token,
        };
        this.put('auth/user/confirmEmail', request)
          .then(() => {
            this.$store.commit('setSnackbar', {
              show: true,
              color: 'success',
              message: 'ユーザ認証完了',
            });

            this.confirmed.result = true;
            this.confirmed.message = 'ユーザ認証が完了しました';
          })
          .catch(() => {
            this.$store.commit('setSnackbar', {
              show: true,
              color: 'error',
              message: 'ユーザ認証失敗',
            });

            this.confirmed.result = false;
            this.confirmed.message = 'ユーザ認証に失敗しました。内容を確認して再度実行してください';
          });
      }

      //

      this.loadCookie();
    },

    loadCookie() {
      let email = this.$cookies.get('email');
      let password = this.$cookies.get('password');
      let save = this.$cookies.get('save');
      //
      email = email == 'null' ? null : email;
      password = password == 'null' ? null : password;
      save = save != 'null';

      this.login.email = email;
      this.login.password = password;
      this.login.save = save;
    },

    onLogin() {
      if (this.isLogined) {
        this.movePage();
        return;
      }
    },

    onChange(val) {
      this.$router.push({ name: 'signin', params: { state: val } });
    },

    onPost() {
      // validate
      if (!this.$refs.Create.validate()) return;

      let request = {
        email: this.creator.email,
        password: this.creator.password,
      };

      this.post('auth/user', request)
        .then(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'success',
            message: 'ユーザー仮登録しました。登録したメールアドレスに送られたURLから認証を行ってください',
          });

          //
          this.onChange(State.Login);
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'error',
            message: 'ユーザ登録に失敗しました。登録内容を確認してください',
          });
        });
    },

    onResetPassword() {
      // validate
      if (!this.$refs.ResetPassword.validate()) return;

      let request = {
        code: this.code,
        email: this.email,
        token: this.token,
        password: this.resetPassword.password,
        confirmedPassword: this.resetPassword.confirmedPassword,
      };

      this.post('auth/user/resetPassword', request)
        .then(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'success',
            message: 'パスワードをリセットしました。',
          });

          //
          this.onChange(State.Login);
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'error',
            message: 'パスワードリセットに失敗しました。登録内容を確認し再度試してください',
          });
        });
    },

    onForget() {
      // validate
      if (!this.$refs.Forget.validate()) return;

      let request = {
        email: this.forget.email,
      };

      this.post('auth/user/forgetPassword', request)
        .then(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'success',
            message: '登録したメールアドレスに送られたURLからパスワードの再登録を行ってください',
          });

          //
          this.onChange(State.Login);
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'error',
            message: 'パスワード再発行処理に失敗しました',
          });

          //
          this.onChange(State.Login);
        });
    },
  },
  watch: {},
  mounted() {
    setTimeout(() => {
      // 反映されるのを待ってから実行
      // 初期処理
      this.onLoaded();
    }, 0);
  },
};
</script>
